<!--
 * @Description: 变更申请管理
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-07-28 19:23:57
 * @LastEditors: Seven
 * @LastEditTime: 2022-04-27 11:37:40
-->
<template>
  <div class="change-list">
    <div ref="contentTop">
      <div class="tool">
        <div class="customer-tool">
          <span class="tab-bar clearfix">
            <span
              @click="handleClick(item.path)"
              v-for="(item, index) in tabList"
              :class="{ active: item.path === activeName }"
              :key="index"
              >{{ item.name }}</span
            >
          </span>
          <div style="line-height: 40px">
            <!-- 导出 -->
            <!-- <Export v-if="permUtil.WbCusotmer.invalidExport()" :pageParam="pageParam" :searchParam="searchParam" :total="total" code="inspect" /> -->
          </div>
        </div>
      </div>
      <div style="width: 94%; margin: 0 auto">
        <Search
          v-if="searchParamList.length > 0"
          :searchAllParam="searchAllParam"
          :searchTearchList="searchTearchList"
          @search="search"
          @reset="reset"
          @searchMore="searchMore"
          @searchList="searchList"
          :searchParamList="searchParamList"
          :searchParamFromApi="searchParamFromApi"
          code="changeList"
        />
      </div>
      <div class="line"></div>
    </div>
    <el-row style="width: 94%; margin: 0 auto">
      <el-col>
        <el-table
          highlight-current-row
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          :height="tableHeight"
          border
          stripe
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
          :header-cell-style="{ background: '#e5f2ff' }"
          @selection-change="selectionRow"
          @sort-change="sortChange"
        >
          <el-table-column :show-overflow-tooltip="true"
            label="订单号"
            prop="orderNo"
            min-width="160"
          >
            <template slot-scope="scope">
              <div style="color: #409EFF;cursor:pointer" @click="jumporderClick(scope.row)">{{ scope.row.orderNo }}</div>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="变更类型"
            prop="changeType"
            min-width="120"
            
          >
            <template slot-scope="scope">
              <div>{{ scope.row.changeType | convert(changeTypeList) }}</div>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="变更理由"
            prop="changeResson"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="变更前"
            prop="changeBeforeName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="变更后"
            prop="changeAfterName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="状态"
            prop="approveState"
            min-width="120"
            
          >
            <template slot-scope="scope">
              <div :style="{color: scope.row.approveState == 0?'green':scope.row.approveState == 3?'red':''}">{{ scope.row.approveState | convert(approveList) }}</div>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="校区"
            prop="dealSchoolName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="创建人"
            prop="createName"
            min-width="120"
            
          >
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="创建时间"
            prop="createTime"
            min-width="150"
            
          >
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="审核人"
            prop="approveName"
            min-width="120"
            
          >
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="审核时间"
            prop="approveTime"
            min-width="150"
            
          >
            <template slot-scope="scope">
              <div>{{ scope.row.approveTime | formatDate }}</div>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="驳回理由"
            prop="approveOpinion"
            min-width="120"
            
          >
          </el-table-column>>
          <el-table-column label="操作" min-width="200"  fixed="right">
            <template slot-scope="scope">
              <el-button v-if="scope.row.approveState === 2 && permUtil.WbOrder.orderChangeAdopt()" type="text" @click="changeClick(scope.row, 0)"
                >通过</el-button
              >
              <el-button v-if="scope.row.approveState === 2 && permUtil.WbOrder.orderChangeReject()" style="color: red!important" type="text" @click="changeClick(scope.row, 3)"
                >驳回</el-button
              >
              <el-button v-if="scope.row.approveState === 2 && permUtil.WbOrder.orderChangeRevoke()" type="text" @click="changeClick(scope.row, 1)"
                >撤销</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="page_test_bottom">
          <el-pagination
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageParam.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageParam.pageSize"
            :total="total"
          />
        </div>
      </el-col>
    </el-row>
    <HqModal :visible="inspectShow" title="驳回" width="450" @on-cancel="cancel">
      <el-form ref="form" :model="form" :rules="formRules">
        <el-row class="inspect-modal">
          <el-col :span="24">
            <el-form-item prop="approveOpinion">
              <v-textarea
                v-model="form.approveOpinion"
                outlined
                :height="80"
                placeholder="输入审批意见，60字内"
                name="input-7-4"
                label="审批意见"
              ></v-textarea>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="editSave" type="primary">确认</el-button>
      </div>
    </HqModal>
  </div>
</template>
<script>
import Search from "components/search";
import { getUserCustomQuery, getResTeachList, getRecommentUserList } from "api/public";
import { getTyChangeList, doUpdateOrderDetail } from "api/order/change";

export default {
  name: "changeList",
  components: {
    Search,
  },
  data() {
    return {
      activeName: "-1",
      tabList: [
        { name: "所有", path: "-1" },
        { name: "变更销售", path: "1" },
        { name: "变更招生", path: "0" },
        { name: "变更转介绍", path: "2" },
      ],
      searchAllParam: [
        { name: "orderNo", label: "订单号", type: "input", value: "" },
        {
          name: "createTime",
          label: "创建时间",
          type: "date",
          value: [],
          ref: "create",
          show: false,
        },
        {
          name: "approveTime",
          label: "审核时间",
          type: "date",
          value: [],
          ref: "invalid",
          show: false,
        },
        { name: "changeResson", label: "变更理由", type: "input", value: "" },
        // {
        //   name: "changeBeforeCrmId",
        //   label: "变更前老师",
        //   type: "select",
        //   value: "",
        //   option: [],
        //   id: "userId",
        //   text: "userName",
        // },
        // {
        //   name: "changeAfterCrmId",
        //   label: "变更后老师",
        //   type: "select",
        //   value: "",
        //   option: [],
        //   id: "userId",
        //   text: "userName",
        // },
        {
          name: "approveState",
          label: "状态",
          type: "select",
          value: "",
          option: [
            {name: '通过', id: 0},
            {name: '撤销', id: 1},
            {name: '待审核', id: 2},
            {name: '驳回', id: 3},
          ],
          text: "name",
          id: "id"
        },
      ],
      searchParamList: [],
      defaultParamList: ["name", "phone", "createTime"],
      searchParamFromApi: [],
      tableData: [],
      listLoading: false,
      total: 0,
      pageParam: {
        pageNum: 1,
        pageSize: 20,
        sortItems: [
          {
            asc: false,
            column: "",
          },
        ],
      },
      inspectShow: false,
      form: {
        approveOpinion: "",
      },
      deptList: [],
      searchParam: {},
      formRules: {
        approveOpinion: [
          {message: "请输入驳回理由", required: true}
        ] 
      },
      // 变更类型
      changeTypeList: {
        0: '变更招生',
        1: '变更销售',
        2: '变更转介绍'
      },
      // 状态
      approveList: {
        0: '通过',
        1: '撤销',
        2: '待审核',
        3: '驳回'
      },
      searchTearchList: {},
      topHeight: 0,
    };
  },
  computed: {
    tableHeight() {
      return window.outerHeight - this.topHeight - 250 + 'px'
    }
  },
  mounted() {
    this.getSearchList();
    this.getTyChangeList();
    // this.searchAllParam.map((item) => {
    //   if(item.name === 'changeBeforeCrmId' || item.name === 'changeAfterCrmId') {
    //     getResTeachList().then(data => item.option = data.result)
    //   }
    //  });
    this._getTeacherFindAll()
  },
  methods: {
    jumporderClick(dataItem) {
      console.log('触发了', dataItem);
      let path = `${dataItem.businessId}` === '1' ?  "/afterSale/cjOrderDetail": "/afterSale/xlOrderDetail";
      this.$router.push( {
        path: path,
        query: {
          orderId: dataItem.orderId,
          status: dataItem.orderStatus,
          pathName: 'changerouter'
        },
      });
    },
    // 获取老师下拉框
    async _getTeacherFindAll() {
      let params = {
        pageSize: 10,
        curPage: 1
      }
      let data =  await this.$fetch("getTeacherFindAll", params)
      this.searchTearchList = data
    },
    // tab切换
    handleClick(index) {
      this.activeName = index;
      this.$router.push({ query: { type: this.activeName } });
      this.getTyChangeList()
    },
    // 更新自定义查询
    searchList() {
      this.getSearchList();
    },
    // 更多查询条件
    searchMore(value) {
      this.searchParamList = value
        ? this.searchAllParam
        : this.defaultParamList;
      this.$nextTick(() => {
        this.topHeight = this.$refs.contentTop.offsetHeight + 30
      })
    },
    // 获取自定义查询的默认查询字段
    async getSearchList() {
      const { code, result } = await getUserCustomQuery("changeList");
      if (code === 0) {
        this.defaultParamList = ["name", "phone", "createTime"]
        if (result && result.fieldData && JSON.parse(result.fieldData).length > 0) {
          this.searchParamFromApi = JSON.parse(result.fieldData);
          
          this.defaultParamList = []
          this.searchParamFromApi.map(() => {
            this.defaultParamList.push("")
          })
          let index
          this.searchAllParam.filter((item) => {
            index = this.searchParamFromApi.findIndex(i => i === item.name)
            this.defaultParamList[index] = item
          });
        } else {
          this.defaultParamList = this.searchAllParam.filter((item) =>
            this.defaultParamList.includes(item.name)
          );
        }
        this.searchParamList = this.defaultParamList;
        this.$nextTick(() => {
          this.topHeight = this.$refs.contentTop.offsetHeight + 40
        })
      }
    },
    // 获取列表数据
    async getTyChangeList() {
      if(this.activeName != -1) {
        this.pageParam.changeType = this.activeName;
        this.searchParam.changeType = this.activeName;
      } else {
        delete this.pageParam.changeType
        delete this.searchParam.changeType
      }
      this.listLoading = true;
      const { code, result, msg } = await getTyChangeList({...this.pageParam, ...this.searchParam});
      if (code === 0) {
        this.tableData = result.list;
        this.total = result.total;
      } else {
        this.$message.error(msg)
      }
      this.listLoading = false;
    },
    // 多选事件--
    selectionRow(val) {
      this.currList = val;
    },

    // 改变分页条数
    sizeChange(val) {
      this.pageParam.pageSize = val;
      this.getTyChangeList();
    },

    // 改变分页
    currentChange(val) {
      this.pageParam.pageNum = val;
      this.getTyChangeList();
    },
    // 排序
    sortChange(val) {
      this.pageParam.sortItems[0].column = val.prop;
      this.getTyChangeList();
    },
    // 查询
    search() {
      this.searchParam = {};
      this.searchParamList.map((item) => {
        if (item.value || item.value == 0) {
          if (item.type === "date") {
            if (item.name === "createTime") {
              this.searchParam.beginCreateTime = item.value[0];
              this.searchParam.endCreateTime = item.value[1];
            } else if (item.name === "approveTime") {
              this.searchParam.beginApproveTime = item.value[0];
              this.searchParam.endApproveTime = item.value[1];
            }
          } else {
            this.searchParam[item.name] = item.value;
          }
        }
      });
      this.getTyChangeList();
    },
    // 重置
    reset() {
      this.searchParamList.map((item) => {
        if (item.value || item.value == 0) {
          if(item.name === "createTime" ||
          item.name === "approveTime") {
            item.value = []
          } else {
            item.value = ""; 
          }
        }
      });
      this.pageParam = {
        pageNum: 1,
        pageSize: 20,
        sortItems: [
          {
            asc: false,
            column: "",
          },
        ],
      };
      this.search();
    },
    // 通过
    changeClick(row, type) {
      if(type === 0) {
        this.$hqMessageBox({
          title: "通过",
          message:
            "确定审核通过此单据变更信息？",
          })
          .then(() => {
            doUpdateOrderDetail({...row, ...{approveState: type}}).then((data) => {
              const { code, msg } = data
              if (code === 0) {
                this.$message.success(msg)
                this.getTyChangeList()
              } else {
                this.$message.error(msg)
              }
            })
          })
          .catch(() => {
            console.log("取消");
          })
      } else if(type === 1) {
        // 撤销
        this.$hqMessageBox({
          title: "撤销",
          message:
            "确定审核撤销此单据变更信息？",
          })
          .then(() => {
            doUpdateOrderDetail({...row, ...{approveState: type}}).then((data) => {
              const { code, msg } = data
              if (code === 0) {
                this.$message.success(msg)
                this.getTyChangeList()
              } else {
                this.$message.error(msg)
              }
            })
          })
          .catch(() => {
            console.log("取消");
          })
      } else if(type === 3) {
        // 驳回
        this.inspectShow = true;
        this.form = {...row, ...{approveState: type}}
      }
    },
    // 保存编辑
    editSave() {
      this.$refs["form"].validate(async (valid) => {
        if(valid) {
          const { code, msg } = await doUpdateOrderDetail(this.form);
            if (code === 0) {
              this.$message.success(msg);
              this.getTyChangeList();
              this.inspectShow = false;
            } else {
              this.$message.error(msg);
            }
        }
      })
    },

    // 取消编辑
    cancel() {
      (this.form = {
        approveOpinion: ""
      }),
        (this.inspectShow = false)
    },
  },
};
</script>
<style lang="scss" scoped>
.change-list {
  // padding-bottom: 40px;
  .tool {
    background: #f5f7f9;
  }

  .customer-tool {
    width: 94%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .tab-bar {
    overflow: hidden;

    .active {
      color: #409eff;
    }

    > span {
      float: left;
      margin-right: 12px;
      line-height: 40px;
      text-align: center;
      text-decoration: none;
      color: #000;
      cursor: pointer;
      font-size: 14px;
    }
  }
  .search-btn {
    position: absolute;
  }

  .tool-btn {
    position: absolute;
    right: 20px;
    top: 0;
  }
}

.inspect-modal {
  width: 288px;
  margin: 0 auto;

  // .el-col-24 {
  //   margin-top: 18px;
  // }
}
</style>